import { api } from "../api";

import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
import { MyNewKennelRegistrationRequest } from "@/types/portal/Workflow";

enum WorkflowPath {
    DogRegistration = '/Dog',
    QTRegistration = '/Dog/qt',
    KennelRegistration = '/Kennel',
    KennelChangeOwners = '/Kennel/owners',
    LitterRegistration = '/Litter',
    LeaseRegistration = '/Lease',
    AffiliateRegistration = '/Affiliate/registration',
    AffiliateRenewal = '/Affiliate/renewal',
}

const getWorkflowFees = async (path: string, id?: string | number): Promise<any> => {
    try {
        let fullPath = `${path}/fees`;

        if (!!id) fullPath += `/${id}`;

        const { data } = await api.get<any>(fullPath);
        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

const getWorkflowsList = async (
    options: PaginationOptions,
    path: WorkflowPath
): Promise<PaginatedResponse<Object>> => {
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<Object>>(`${path}/requests?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`);

    return data;
}

const getWorkflowRequestDetails = async (workflowGuid: string, path: WorkflowPath): Promise<any> => {  
    const { data } = await api.get<any>(`${path}/myrequest/${workflowGuid}`);
    return data;
};

const getSignatureRequestList = async (
    options: PaginationOptions,
    path: WorkflowPath
): Promise<PaginatedResponse<Object>> => {
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<Object>>(`${path}/signatures?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`);

    return data;
}

const getWorkflowDetailsForSignature = async (signatureId: number, path: WorkflowPath): Promise<any> => {
    try {
        const { data } = await api.get<any>(`${path}/signature/${signatureId}`);
        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

const signWorkflow = async (
    workflowGuid: String,
    signatureId: number,
    personId: number,
    signatureText: String,
    path: WorkflowPath,
): Promise<any> => {
    try {
        const { data } = await api.post<object>(`${path}/signature`, {
            workflowGuid,
            signatureId,
            personId,
            signatureText
        });

        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

const submitWorkflowCCAuthorization = async (
    request: Object,
    path: WorkflowPath
): Promise<any> => {
    try {
        const { data } = await api.post<object>(`${path}/paymentauth`, request);
        return data;
    } catch (err) {
        console.error('error', err);
        return err.data;
    }
}

const submitWorkflowCCPayment = async (
    request: Object,
    path: WorkflowPath
  ): Promise<any> => {
    try {
      const { data } = await api.post<object>(`${path}/payment`, request);
      return data;
    } catch (err) {
      console.error("error", err);
      return err.data;
    }
};

// ---- FEES ----

export const getRegisterQTFees = async (): Promise<any> =>
    await getWorkflowFees(`${WorkflowPath.QTRegistration}/registration`);

export const getRegisterKennelFees = async (): Promise<any> =>
    await getWorkflowFees(`${WorkflowPath.KennelRegistration}/registration`);

export const getChangeKennelOwnerFees = async (): Promise<any> =>
    await getWorkflowFees(`${WorkflowPath.KennelChangeOwners}/registration`);

export const getRegisterLitterFees = async (): Promise<any> =>
    await getWorkflowFees(`${WorkflowPath.LitterRegistration}/registration`);

export const getRegisterLeaseFees = async (): Promise<any> =>
    await getWorkflowFees(`${WorkflowPath.LeaseRegistration}/registration`);

export const getLeaseFeeForPerson = async (personId: number): Promise<any> =>
    await getWorkflowFees(`${WorkflowPath.LeaseRegistration}/registration`, personId);

export const getRegisterAffiliateFees = async (): Promise<any> =>
    await getWorkflowFees(WorkflowPath.AffiliateRegistration);

export const getRenewAffiliateFees = async (affiliateId: string): Promise<any> =>
    await getWorkflowFees(WorkflowPath.AffiliateRenewal, affiliateId);

// ---- LIST ----

export const getQTDogRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.QTRegistration);

export const getKennelRegistrationRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.KennelRegistration);

export const getChangeKennelOwnerRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.KennelChangeOwners);

export const getLitterRegistrationRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.LitterRegistration);

export const getLeaseRegistrationRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.LeaseRegistration);

export const getAffiliateRegistrationRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.AffiliateRegistration);

export const getAffiliateRenewalRequests = async (options: PaginationOptions): Promise<any> =>
    await getWorkflowsList(options, WorkflowPath.AffiliateRenewal);

// ---- DETAILS ----

export const getQTDogRequestDetails = async (workflowGuid: string): Promise<any> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.QTRegistration);

export const getKennelRequestDetails = async (workflowGuid: string): Promise<MyNewKennelRegistrationRequest> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.KennelRegistration);

export const getChangeKennelOwnerRequestDetails = async (workflowGuid: string): Promise<any> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.KennelChangeOwners);

export const getLitterRequestDetails = async (workflowGuid: string): Promise<any> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.LitterRegistration);

export const getLeaseRequestDetails = async (workflowGuid: string): Promise<any> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.LeaseRegistration);

export const getAffiliateRegistrationRequestDetails = async (workflowGuid: string): Promise<any> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.AffiliateRegistration);

export const getAffiliateRenewalRequestDetails = async (workflowGuid: string): Promise<any> =>
    await getWorkflowRequestDetails(workflowGuid, WorkflowPath.AffiliateRenewal);

// ---- SIGNATURE LIST ----

export const getQTDogSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.QTRegistration);

export const getKennelRegistrationSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.KennelRegistration);

export const getChangeKennelOwnerSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.KennelChangeOwners);

export const getLitterRegistrationSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.LitterRegistration);

export const getLeaseRegistrationSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.LeaseRegistration);

export const getAffiliateRegistrationSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.AffiliateRegistration);

export const getAffiliateRenewalSignatureRequests = async (options: PaginationOptions): Promise<any> =>
    await getSignatureRequestList(options, WorkflowPath.AffiliateRenewal);

// ---- SIGNATURE DETAILS ----

export const getQTDogDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.QTRegistration);

export const getKennelRegistrationDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.KennelRegistration);

export const getChangeKennelOwnerRequestDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.KennelChangeOwners);

export const getLitterRegistrationDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.LitterRegistration);

export const getLeaseDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.LeaseRegistration);

export const getAffiliateRegistrationDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.AffiliateRegistration);

export const getAffiliateRenewalDetailsForSignature = async (signatureId: number): Promise<any> =>
    await getWorkflowDetailsForSignature(signatureId, WorkflowPath.AffiliateRenewal);

// ---- SIGN SIGNATURE ----

export const signDog = async (
    workflowGuid: String,
    signatureId: number,
    personId: number,
    signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.DogRegistration);

export const signKennelRegistration = async (
    workflowGuid: String,
    signatureId: number,
    personId: number,
    signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.KennelRegistration);

export const signChangeKennelOwnerRequest = async (
    workflowGuid: String,
    signatureId: number,
    personId: number,
    signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.KennelChangeOwners);

export const signLitterRegistration = async (
  workflowGuid: String,
  signatureId: number,
  personId: number,
  signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.LitterRegistration);

export const signLease = async (
  workflowGuid: String,
  signatureId: number,
  personId: number,
  signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.LeaseRegistration);

export const signAffiliateRegistration = async (
    workflowGuid: String,
    signatureId: number,
    personId: number,
    signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.AffiliateRegistration);

export const signAffiliateRenewal = async (
    workflowGuid: String, 
    signatureId: number,
    personId: number,
    signatureText: String
): Promise<any> => await signWorkflow(workflowGuid, signatureId, personId, signatureText, WorkflowPath.AffiliateRenewal);

// ---- PAYMENT AUTHORIZATION ----

export const submitKennelCCAuthorization = async (request: Object): Promise<any> =>
    await submitWorkflowCCAuthorization(request, WorkflowPath.KennelRegistration);

export const submitKennelOwnerCCAuthorization = async (request: Object): Promise<any> =>
    await submitWorkflowCCAuthorization(request, WorkflowPath.KennelChangeOwners);

export const submitAffiliateRenewalCCAuthorization = async (request: Object): Promise<any> =>
    await submitWorkflowCCAuthorization(request, WorkflowPath.AffiliateRenewal);
    
export const submitAffiliateRegistrationCCAuthorization = async (request: Object): Promise<any> =>
    await submitWorkflowCCAuthorization(request, WorkflowPath.AffiliateRegistration);

// ---- PAYMENT ----

export const submitDogCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.DogRegistration);

export const submitKennelCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.KennelRegistration)

export const submitKennelOwnerCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.KennelChangeOwners);

export const submitLitterCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.LitterRegistration);

export const submitLeaseCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.LeaseRegistration);

export const submitAffiliateRegistrationCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.AffiliateRegistration);

export const submitAffiliateRenewalCCPayment = async (request: Object): Promise<any> =>
    await submitWorkflowCCPayment(request, WorkflowPath.AffiliateRenewal);

export default {
    // ---- FEES ----
    getRegisterQTFees,
    getRegisterKennelFees,
    getChangeKennelOwnerFees,
    getRegisterLitterFees,
    getRegisterLeaseFees,
    getLeaseFeeForPerson,
    getRegisterAffiliateFees,
    getRenewAffiliateFees,
    // ---- LIST ----
    getQTDogRequests,
    getKennelRegistrationRequests,
    getChangeKennelOwnerRequests,
    getLitterRegistrationRequests,
    getLeaseRegistrationRequests,
    getAffiliateRegistrationRequests,
    getAffiliateRenewalRequests,
    // ---- DETAILS ----
    getQTDogRequestDetails,
    getKennelRequestDetails,
    getChangeKennelOwnerRequestDetails,
    getLitterRequestDetails,
    getLeaseRequestDetails,
    getAffiliateRegistrationRequestDetails,
    getAffiliateRenewalRequestDetails,
    // ---- SIGNATURE LIST ----
    getQTDogSignatureRequests,
    getKennelRegistrationSignatureRequests,
    getChangeKennelOwnerSignatureRequests,
    getLitterRegistrationSignatureRequests,
    getLeaseRegistrationSignatureRequests,
    getAffiliateRegistrationSignatureRequests,
    getAffiliateRenewalSignatureRequests,
    // ---- SIGNATURE DETAILS ----
    getQTDogDetailsForSignature,
    getKennelRegistrationDetailsForSignature,
    getChangeKennelOwnerRequestDetailsForSignature,
    getLitterRegistrationDetailsForSignature,
    getLeaseDetailsForSignature,
    getAffiliateRegistrationDetailsForSignature,
    getAffiliateRenewalDetailsForSignature,
    // ---- SIGN SIGNATURE ----
    signDog,
    signKennelRegistration,
    signChangeKennelOwnerRequest,
    signLitterRegistration,
    signLease,
    signAffiliateRegistration,
    signAffiliateRenewal,
    // ---- AUTHORIZE PAYMENT ----
    submitKennelCCAuthorization,
    submitKennelOwnerCCAuthorization,
    submitAffiliateRenewalCCAuthorization,
    submitAffiliateRegistrationCCAuthorization,
    // ---- PAYMENT ----
    submitDogCCPayment,
    submitKennelCCPayment,
    submitKennelOwnerCCPayment,
    submitLitterCCPayment,
    submitLeaseCCPayment,
    submitAffiliateRegistrationCCPayment,
    submitAffiliateRenewalCCPayment
}